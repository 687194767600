import styled from "styled-components";

export const FirstBlock = styled.div`
  background: url("/images/main.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 80vh;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 100px !important;
  z-index: 1000;
  a{
    color: white;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    margin-right: 20px;
  }
`

export const Header = styled.div`
  width: 1220px;
  margin: 0 auto;
  color: white;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 1230px) {
    width: 90%;
  }
  @media(max-width: 450px) {
    a{
      font-size: 18px;
    }
    h1{
      font-size: 22px;
    }
  }
`

export const AboutWebApp = styled.div`
  width: 800px;
  color: white;
  margin: 0 auto;
  text-align: center;
  margin-top: 5%;
  h1{
    font-size: 112px;
  }
  p{
    width: 80%;
    font-size: 30px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: 600;
  }
  div{
    margin-top: 50px;
    button{
      background: rgb(0,212,255);
      background: linear-gradient(90deg, rgba(0,212,255,1) 0%,
      rgba(11,11,161,1) 63%, rgba(9,0,158,1) 100%);
      width: 170px;
      color: white;
      height: 50px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      border-radius: 8px;
      margin: 10px 20px;
      a{
        font-size: 16px;
        margin: 0;
      }
    }
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 850px) {
    width: 80%;
    h1{
      font-size: 80px;
    }
  }
  @media(max-width: 650px) {
    h1{
      margin-top: 100px;
      font-size: 60px;
    }
    p{
      font-size: 22px;
    }
  }
  @media(max-width: 500px){
    h1{
      font-size: 40px;
    }
    p{
      width: 90%;
      font-size: 18px;
    }
    button{
      height: 50px !important;
      width: 140px !important;
    }
    div{
      margin-top: 20px;
    }
  }
  
`

export const CountersBlock = styled.div`
  width: 1440px;
  margin: 0 auto; 
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  div{
    margin: 20px;
    h1{
      white-space: nowrap;
      font-size: 112px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, rgba(0,212,255,1) 0%,
      rgba(11,11,161,1) 63%, rgba(9,0,158,1) 100%);
    }
    h2{
      color: white;
      font-size: 30px;
    }
  }
  @media(max-width: 1480px) {
    width: 95%;
    div{
      h1{
        font-size: 90px;
      }
    }
  }
  @media(max-width: 1220px) {
    div{
      h1{
        font-size: 70px;
      }
    }
  }
  @media(max-width: 980px) {
    div{
      h1{
        font-size: 50px;
      }
      h2{
        font-size: 20px;
      }
    }
  }
  @media(max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    div{
      margin: 0 auto;
      h1{
        font-size: 60px;
      }
      h2{
        font-size: 20px;
        text-align: center;
      }
    }
  }
  @media(max-width: 450px) {
    margin-top: 0 !important;
    div{
      margin-top: 10px !important;
      margin-bottom: 0 !important;
      h1{
        font-size: 40px;
      }
      h2{
        font-size: 20px;
      }
    }
  }
`

export const SecondBlock = styled.div`
  background: rgb(22,22,25);
  background: linear-gradient(90deg, rgba(22,22,25,1) 17%, rgba(11,9,9,1) 100%);
  z-index: 0;
  padding-top: -40px !important;
  @media(max-width: 700px) {
    padding-top: 70px;
  }
`

export const AboutSecondBlock = styled.div`
  border: 1.5px solid white;
  border-radius: 12px;
  width: 600px;
  margin: 0 auto;
  color: white;
  font-weight: 600;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  @media(max-width: 620px) {
    width: 90%;
  }
`

export const AboutBlock = styled.h1`
    color: white;
  text-align: center;
  margin: 100px 10px 20px 0;
  span{
    color: #f2c108;
  }
  @media(max-width: 600px) {
    font-size: 24px;
  }
`

export const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 1220px;
  margin: 0 auto;
  div{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: 20px;
    width: 300px;
    img{
      width: 200px;
      height: 200px;
      border-radius: 50%;
      object-fit: cover;
    }
    h3{
      text-align: center;
      margin-top: 10px;
      color: white;
      span{
        color: #f2c108;
      }
    }
    h2{
      color: #f2c108;
      margin-top: 10px;
      text-align: center;
      font-size: 20px;
    }
  }
  @media(max-width: 1260px) {
    width: 95%;
    flex-wrap: wrap;
  }
`

export const HR = styled.hr`
  width: 50%;
  border: none;
  margin: 0 auto;
  background: rgb(7,7,126);
  margin-top: 40px;
  border-radius: 20px;
  background: linear-gradient(90deg, rgba(7,7,126,1) 25%, rgba(7,8,74,1) 30%, 
  rgba(7,8,74,1) 70%, rgba(7,7,126,1) 75%);
  height: 2px;
  @media(max-width: 800px) {
    width: 80%;
  }
`

export const ConcatList = styled.div`
  width: 500px;
  margin: 0 auto;
  @media(max-width: 550px) {
    width: 90%;
  }
`

export const ConcatBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  img{
    width: 30px;
    height: 30px;
  }
  div{
    margin-left: 10px;
    h3{
      color: #f2c108;
    }
    h4{
      color: white;
    }
  }
`

export const LogisticBlock = styled.div`
  display: flex;
  width: 700px;
  margin: 0 auto;
  @media(max-width: 740px) {
    width: 95%;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Logistic = styled.div`
  border: 1px solid white;
  border-radius: 24px;
  color: white;
  padding: 40px;
  margin: 20px;
  ul{
    margin-top: 20px;
    li{
      margin-top: 10px;
      list-style-type: none;
      display: flex;
      align-items: center;
      img{
        width: 20px;
        margin-right: 10px;
      }
    }
  }
`

export const GreatRussia = styled.div`
  width: 1000px;
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  div{
    h3{
      color: white;
      margin-bottom: 5px;
    }
    span{
      font-weight: bold;
      color: rgba(11,11,161,1);
      font-size: 18px;
    }
    p{
      margin-top: 10px;
      color: white;
      img{
        width: 32px;
      }
    }
  }
  @media(max-width: 1020px) {
    width: 90%;
    flex-wrap: wrap;
    img{
      max-width: 400px;
    }
  }
  @media(max-width: 420px){
    img{
      width: 300px;
    }
  }
`

export const Telegram = styled.div`
  width: 800px;
  margin: 0 auto;
  img{
    width: 350px;
    margin: 20px;
  }
  @media(max-width: 850px) {
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img{
      width: 60%;
    }
  }
`

export const Team = styled.div`
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media(max-width: 1140px) {
    flex-wrap: wrap;
    width: 95%;
  }
`

export const Mate = styled.div`
  margin: 20px;
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  img{
    width: 180px;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
  }
  h3{
    color: white;
    margin-top: 10px;
    text-align: center;
  }
  a{
    color: white;
    margin-top: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }
`
export const Modal = styled.div`
  position: fixed;
  margin: 0 auto;
  background: rgba(0, 2, 51, .7);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  img{
  width: 25px;
    margin-bottom: 20px;
  }
}
  form{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: 300px;
    margin: 0 auto;
    background: black;
    padding: 40px;
    border-radius: 20px;
    label{
      color: white;
      margin-bottom: 5px;
    }
    input{
      height: 25px;
      border-radius: 5px;
      padding-left: 10px;
      outline: none;
    }
    input[type=textarea]{
      height: 40px;
    }
    button{
      background: rgb(0,212,255);
      background: linear-gradient(90deg, rgba(0,212,255,1) 0%,
      rgba(11,11,161,1) 63%, rgba(9,0,158,1) 100%);
      width: 120px;
      margin-top: 10px;
      height: 40px;
      border: none;
      border-radius: 8px;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
`
export const Footer = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  form{
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: 300px;
    margin: 0;
    label{
      color: white;
      margin-bottom: 5px;
    }
    input{
      height: 25px;
      border-radius: 5px;
      padding-left: 10px;
      outline: none;
    }
    input[type=textarea]{
      height: 40px;
    }
    button{
      background: rgb(0,212,255);
      background: linear-gradient(90deg, rgba(0,212,255,1) 0%,
      rgba(11,11,161,1) 63%, rgba(9,0,158,1) 100%);
      width: 120px;
      margin-top: 10px;
      height: 40px;
      border: none;
      border-radius: 8px;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
  }
  iframe{
    width: 400px;
    margin: 20px;
  }
  @media(max-width: 500px) {
    iframe{
      width: 90%;
    }
  }
  @media(max-width: 1240px) {
    width: 95%;
    flex-wrap: wrap;
  }
`

export const Contacts = styled.div`
  margin-left: 20px;
  width: 300px;
  div{
    display: flex;
    margin-top: 20px;
    align-items: center;
    img{
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    address, a{
      color: white;
      text-decoration: none;
    }
  }
  @media(max-width: 1240px) {
    margin-left: 0;
  }
`

